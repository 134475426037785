import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUser } from "../api/user.api";

export interface StateUserInterface {
  isLoadingUser: boolean;
  userId?: null | number;
  userName?: null | string;
  userAccess?: null | number[];
  userRoutesAccess?: string[];
  userRole?: null | string;
  userDepartment?: null | number;
  userFetchingStatus?: null | string;
  isErrorUser?: boolean;
  errorMessageUser?: string;
}

export const defaultStateUser: StateUserInterface = {
  isLoadingUser: false,
  userId: null,
  userName: null,
  userAccess: null,
  userRole: null,
  userDepartment: null,
  userFetchingStatus: null,
  userRoutesAccess: [],
  isErrorUser: false,
  errorMessageUser: "",
};

const user = createSlice({
  name: "user",
  initialState: defaultStateUser,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        token: string;
      }>,
    ) {
      return {
        ...state,
        isLoadingUser: false,
        token: action.payload.token,
      };
    },
    removeUser() {
      return { ...defaultStateUser, isLoadingUser: false };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchUser.pending, () => {
      return {
        ...defaultStateUser,
        isLoadingUser: true,
        errorMessageUser: "",
        isErrorUser: false,
      };
    });
    builder.addCase(fetchUser.rejected, (state, action: any) => {
      state.isLoadingUser = false;
      state.errorMessageUser = action.payload.message;
      state.isErrorUser = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      // Add user to the state array
      state.isLoadingUser = false;
      state.userId = action.payload.userId;
      state.userDepartment = action.payload.userDepartment;
      state.userName = action.payload.userName;
      state.userRole = action.payload.userRole;
    });
  },
});

export const { setUser, removeUser } = user.actions;

export default user.reducer;
