import { memo } from "react";
import CustomButton from "../../../components/CustomButton";
import { TableItemType } from "../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../components/tables/TableWidget";
import { postBlockUser, postUnpairDevice } from "../../../redux/api/users.api";
import { useAppDispatch } from "../../../redux/hooks";
import { UserInterface } from "../../../redux/interfaces/users.interface";
import Text from "../../../styleguide/Text";

function UsersTableActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData: UserInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const handleUnpair = () => {
    if (crudData.id_user_device) {
      dispatch(postUnpairDevice({ idUserDevice: crudData.id_user_device }));
    }
  };

  const handleBlock = () => {
    dispatch(
      postBlockUser({
        userId: crudData.id_user,
        blocked: crudData.blocked === 1 ? 0 : 1,
      }),
    );
  };

  return (
    <td className="text-start" style={{ width: 250 }}>
      {crudData.id_user_device && (
        <CustomButton
          className="me-1 mb-2"
          variant="contained"
          color="primary"
          fullWidth
          withConfirmationModal={{
            modalRightButtonOnClick: handleUnpair,
            modalTitle: `Unpair device from ${crudData.username}`,
            modalSubtitle: "Are you sure you want to unpair this device?",
          }}>
          <Text variant="caption" className="indicator-label" isBold>
            Unpair
          </Text>
        </CustomButton>
      )}

      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        withConfirmationModal={{
          modalRightButtonOnClick: handleBlock,
          modalTitle: `Block/Unblock user ${crudData.username}`,
          modalSubtitle: "Are you sure you want to block/unblock this user?",
        }}>
        <Text variant="caption" className="indicator-label" isBold>
          {crudData.blocked === 1 ? "Unblock" : "Block"}
        </Text>
      </CustomButton>
    </td>
  );
}

export default memo(UsersTableActionButtons);
