import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const searchParamsData: any = {};

  const searchParamsEntries = searchParams.entries();
  //   @ts-ignore
  for (const entry of searchParamsEntries) {
    const [param, value] = entry;
    searchParamsData[param] = value;
  }

  return {
    urlParams: searchParamsData,
  };
};

export default useQueryParams;
