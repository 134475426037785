import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Statistics from "../pages/Statistics";
import { fetchUser } from "../redux/api/user.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { removeUserToken } from "../redux/reducer/auth.reducer";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import routesConstants from "./routesConstants";
import ScrollToTop from "./scrollToTop";
import Posts from "../pages/Posts";

const errotRoutes = [
  {
    name: "Error",
    path: "error/*",
    element: <ErrorsPage />,
  },
  {
    name: "Error500",
    path: "/error/500",
    element: <Error500 />,
  },
  {
    name: "Error404",
    path: "*",
    element: <Error404 />,
  },
];

const MainRoutes = () => {
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // let location = useLocation();
  // return <Navigate to="/login" state={{ from: location }} />;
  const dispatch = useAppDispatch();

  const {
    auth: { token },
  } = useAppSelector((state) => ({
    auth: state.auth,
  }));

  useEffect(() => {
    const localStorageToken = localStorage.getItem("token");
    if (localStorageToken || token) {
      dispatch(fetchUser());
    } else {
      dispatch(removeUserToken());
    }
  }, [dispatch, token]);

  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={routesConstants.initialMainRoute} />}
          />
          {errotRoutes.map(({ name, path, element }) => {
            return <Route key={name} path={path} element={element} />;
          })}
          <Route element={<ProtectedRoute isAuthorized={!!token} />}>
            <Route path={routesConstants.initialMainRoute} element={<Home />}>
              <Route path=":userId" element={<Home />} />
            </Route>
            <Route path={routesConstants.statistics} element={<Statistics />} />
            <Route path={routesConstants.posts} element={<Posts />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAuthorized={!token}
                redirectPath={routesConstants.initialMainRoute}
              />
            }>
            <Route path={"/login"} element={<Login />} />;
            <Route path=":userId" element={<Home />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
};

export default MainRoutes;
