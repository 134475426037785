import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import useQueryParams from "../../../hooks/useQueryParams";
import useTableCtx from "../tableContext/useTableCtx";

function FilterTable({
  value,
  withFilter,
  minW,
}: {
  value: string;
  minW?: number | string;
  withFilter: {
    value: string | number;
    label: string | number;
  }[];
}) {
  const { urlParams } = useQueryParams();
  const { handleTableFilter, tableFilter } = useTableCtx()!;
  const [urlParamsReady, setUrlParamsReady] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number>(
    tableFilter[value],
  );

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(selectedValue, 200);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined) {
        handleTableFilter(value, debouncedSearchTerm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  useEffect(() => {
    if (value && urlParams[value] && !urlParamsReady) {
      setSelectedValue(urlParams[value]);
      setUrlParamsReady(true);

      return;
    }
  }, [urlParams, urlParamsReady, value]);

  const onFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  };

  return (
    <div>
      <select
        name={value}
        data-control={`select-${value}`}
        data-hide-search="true"
        style={{ minWidth: minW ?? 130 }}
        className={`form-select form-select-sm form-select-solid bg-white`}
        value={selectedValue}
        onChange={onFilterChange}>
        {withFilter.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FilterTable;
