import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { ReactSVG } from "react-svg";

const Logo = () => {
  return (
    <Link className="text-reset h-100 w-100" to="/">
      <ReactSVG src={toAbsoluteUrl("/logo.svg")} />
    </Link>
  );
};

export default Logo;
