import { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Tooltip,
  tooltipClasses,
  TooltipProps,
  CircularProgress,
  Button,
  ButtonProps,
} from "@mui/material";
import ConfirmationModal from "./misc/ConfirmationModal";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 20,
    maxWidth: 500,
  },
});
interface CustomProps {
  withDefaultAnchor?: boolean;
  tooltipTitle?: string;
  tooltipArrow?: boolean;
  loading?: boolean;
  loadingSize?: number;
  withConfirmationModal?: {
    modalTitle?: string;
    modalSubtitle?: string;
    modalLeftButtonText?: string;
    modalRightButtonText?: string;
    modalLeftButtonOnClick?: () => void;
    modalRightButtonOnClick?: () => void;
    modalLeftButtonColor?:
      | "error"
      | "info"
      | "inherit"
      | "primary"
      | "secondary"
      | "success";
    modalRightButtonColor?:
      | "error"
      | "info"
      | "inherit"
      | "primary"
      | "secondary"
      | "success";
    modalLeftButtonVariant?: "contained" | "outlined" | "text";
    modalRightButtonVariant?: "contained" | "outlined" | "text";
  };
}

const CustomButton = (props: ButtonProps & CustomProps) => {
  const buttonProps = {
    ...props,
  };
  delete buttonProps.withDefaultAnchor;
  delete buttonProps.tooltipTitle;
  delete buttonProps.tooltipArrow;
  delete buttonProps.loading;
  delete buttonProps.withConfirmationModal;

  const tooltipArrow =
    props.tooltipArrow === undefined ? true : props.tooltipArrow;

  if (props.tooltipTitle) {
    return (
      <CustomWidthTooltip arrow={tooltipArrow} title={props.tooltipTitle}>
        <span>
          <ChildrenWrapper buttonProps={buttonProps} props={props} />
        </span>
      </CustomWidthTooltip>
    );
  }

  if (props.href && !props.withDefaultAnchor && !buttonProps.disabled) {
    delete buttonProps.href;
    if (buttonProps.tooltipTitle) {
      return (
        <LinkWrapper href={props.href}>
          <span>
            <ChildrenWrapper buttonProps={buttonProps} props={props} />
          </span>
        </LinkWrapper>
      );
    }
    return (
      <LinkWrapper href={props.href}>
        <ChildrenWrapper buttonProps={buttonProps} props={props} />
      </LinkWrapper>
    );
  }
  return <ChildrenWrapper buttonProps={buttonProps} props={props} />;
};

const ChildrenWrapper = ({
  buttonProps,
  props,
}: {
  buttonProps: any;
  props: CustomProps;
}) => {
  if (props.withConfirmationModal) {
    return <ConfirmationModalWrapper props={props} buttonProps={buttonProps} />;
  }

  return (
    <Button {...buttonProps} onClick={buttonProps.onClick}>
      {props.loading ? (
        <CircularProgress size={props.loadingSize} color="inherit" />
      ) : (
        buttonProps.children
      )}
    </Button>
  );
};

const ConfirmationModalWrapper = ({
  buttonProps,
  props,
}: {
  buttonProps: any;
  props: CustomProps;
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleClose = () => {
    setShowConfirmationModal(false);
  };

  const handleButtonClick = () => {
    setShowConfirmationModal(true);
  };

  if (!props.withConfirmationModal) {
    return <></>;
  }

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleClose}
        title={props.withConfirmationModal.modalTitle}
        subtitle={props.withConfirmationModal.modalSubtitle}
        leftButtonText={props.withConfirmationModal.modalLeftButtonText}
        leftButtonOnClick={props.withConfirmationModal.modalLeftButtonOnClick}
        rightButtonText={props.withConfirmationModal.modalRightButtonText}
        rightButtonOnClick={props.withConfirmationModal.modalRightButtonOnClick}
        leftButtonColor={props.withConfirmationModal.modalLeftButtonColor}
        rightButtonColor={props.withConfirmationModal.modalRightButtonColor}
        leftButtonVariant={props.withConfirmationModal.modalLeftButtonVariant}
        rightButtonVariant={props.withConfirmationModal.modalRightButtonVariant}
      />
      <Button {...buttonProps} onClick={handleButtonClick}>
        {props.loading ? (
          <CircularProgress size={props.loadingSize} color="inherit" />
        ) : (
          buttonProps.children
        )}
      </Button>
    </>
  );
};

const LinkWrapper = ({ href, children }: any) => {
  return (
    <Link
      to={href}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}>
      {children}
    </Link>
  );
};

export default CustomButton;
