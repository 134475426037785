import { TableHeaderCategoryConfig } from "../../../components/tables/tableContext/TableContext";
import { UserPostsListInterface } from "../../../redux/interfaces/users.interface";

export const postsTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Username",
    minW: 125,
  },
  {
    value: "Description",
    minW: 200,
  },
  {
    value: "Media",
    minW: 125,
  },
  {
    value: "Data",
    minW: 125,
  },
];

export const getPostsTableData: any = (postsList: UserPostsListInterface[]) =>
  postsList?.map((post) => {
    return {
      id: post.id,
      crudData: post,
      data: [
        {
          title: post.userName,
        },
        {
          title: post.description,
        },
        {
          images: post.images?.map(({ image }) => image) || [post.thumbnail],
        },
        {
          badges: [
            {
              badgeText: `${post.likesNumber} likes`,
              badgeColor: "light-primary",
            },
            {
              badgeText: `${post.commentsNumber} comments`,
              badgeColor: "light-primary",
            },
            {
              badgeText: `${post.viewsNumber} views`,
              badgeColor: "light-primary",
            },
          ],
        },
      ],
    };
  });
