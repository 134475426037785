import React from "react";
import CustomButton from "../CustomButton";
import ModalWrapper from "../Modal/ModalWrapper";
import { Box } from "@mui/material";
import Text from "../../styleguide/Text";

export interface ConfirmationModalProps {
  show: boolean;
  handleClose?: () => void;
  title?: string;
  subtitle?: string;
  leftButtonText?: string;
  leftButtonOnClick?: () => void;
  rightButtonText?: string;
  rightButtonOnClick?: () => void;
  leftButtonColor?:
    | "error"
    | "info"
    | "inherit"
    | "primary"
    | "secondary"
    | "success";
  rightButtonColor?:
    | "error"
    | "info"
    | "inherit"
    | "primary"
    | "secondary"
    | "success";
  leftButtonVariant?: "contained" | "outlined" | "text";
  rightButtonVariant?: "contained" | "outlined" | "text";
}

function ConfirmationModal({
  show,
  handleClose,
  title,
  subtitle,
  leftButtonText,
  leftButtonOnClick,
  rightButtonText,
  rightButtonOnClick,
  leftButtonColor,
  rightButtonColor,
  leftButtonVariant = "contained",
  rightButtonVariant = "contained",
}: ConfirmationModalProps) {
  const leftButtonClick = () => {
    handleClose && handleClose();
    leftButtonOnClick && leftButtonOnClick();
  };
  const rightButtonClick = () => {
    handleClose && handleClose();
    rightButtonOnClick && rightButtonOnClick();
  };
  return (
    <ModalWrapper
      isSmallModal
      title={title}
      show={show}
      handleClose={handleClose}>
      {subtitle && (
        <Text className="mb-8" variant="body1">
          {subtitle}
        </Text>
      )}
      <Box display="flex" justifyContent="space-between">
        <CustomButton
          variant={leftButtonVariant}
          color={leftButtonColor}
          onClick={leftButtonClick}>
          {leftButtonText ?? "No"}
        </CustomButton>
        <CustomButton
          variant={rightButtonVariant}
          color={rightButtonColor}
          onClick={rightButtonClick}>
          {rightButtonText ?? "Yes"}
        </CustomButton>
      </Box>
    </ModalWrapper>
  );
}

export default ConfirmationModal;
