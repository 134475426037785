import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import colorPallete from "./colorPallete";

import type {} from "@mui/lab/themeAugmentation";
import { CssBaseline, GlobalStyles } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

const MAIN_BACKGROUND_COLOR = colorPallete.grey[100];

const theme = createTheme({
  palette: {
    ...colorPallete,
  },
  typography: {
    allVariants: {
      marginTop: 0,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h1: {
      fontSize: "1.75rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.35rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1.15rem",
    },
    h6: {
      fontSize: "1.075rem",
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 500,
    },
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          margin: 0,
        },
        selectLabel: {
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "0 10px 30px 0 rgb(82 63 105 / 5%)",
          backgroundColor: colorPallete.white,
          color: colorPallete.grey[700],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          textTransform: "none",
          borderRadius: "0.475rem",
          padding: "calc(0.55rem + 1px) calc(1.25rem + 1px)",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            color: "white",
          },
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            textTransform: "none",
            border: `2px dashed ${colorPallete.blue[500]}`,
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed ${colorPallete.red[500]}`,
          },
        },
      ],
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: colorPallete.white,
        },
      },
    },
  },
});

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: { backgroundColor: MAIN_BACKGROUND_COLOR },
          }}
        />
        {children}
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default ThemeWrapper;
