import { TableHeaderCategoryConfig } from "../../../components/tables/tableContext/TableContext";
import { UserInterface } from "../../../redux/interfaces/users.interface";

export const usersTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Nickname",
    minW: 125,
  },
  {
    value: "Email",
    minW: 200,
  },
  {
    value: "Validation code",
    minW: 125,
  },
  {
    value: "Location",
    minW: 125,
  },

  {
    value: "Mac ID",
    minW: 125,
  },
];

export const getUsersTableData: any = (usersList: UserInterface[]) =>
  usersList?.map((users) => {
    return {
      id: users.id_user,
      crudData: users,
      data: [
        {
          title: users.username,
        },
        {
          title: users.email,
        },
        {
          title: users.code,
        },
        {
          title: users.adress,
        },
        {
          badges: [
            {
              badgeText: users.mac_id,
              badgeColor: "light-primary",
            },
          ],
        },
      ],
    };
  });
