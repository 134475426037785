import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";

import { KTSVG } from "../../helpers/KTSVG";
import Text from "../../styleguide/Text";

type Props = {
  children: any;
  show: boolean;
  handleClose?: () => void;
  title?: string;
  isSmallModal?: boolean;
  headerComponent?: () => JSX.Element;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ModalWrapper = ({
  children,
  show,
  handleClose,
  title,
  isSmallModal,
  headerComponent,
}: Props) => {
  return createPortal(
    <Modal
      tabIndex={1}
      aria-hidden="true"
      dialogClassName={`modal-dialog modal-dialog-centered ${
        !isSmallModal && "mw-900px"
      }`}
      style={{ paddingTop: 70 }}
      show={show}
      onHide={handleClose}>
      <div className="modal-header">
        <div>
          {title && (
            <Text variant="h2" component="h2">
              {title}
            </Text>
          )}
        </div>
        <button
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}>
          <KTSVG
            className="svg-icon-1"
            path="/media/icons/duotune/arrows/arr061.svg"
          />
        </button>
      </div>
      {headerComponent && (
        <div className="modal-header">{headerComponent()}</div>
      )}
      <div className="modal-body py-lg-10 px-lg-10">{children}</div>
    </Modal>,
    modalsRoot,
  );
};

export default ModalWrapper;
