import React, { useEffect } from "react";
import TableItem from "./components/TableItem";
import TableHeaderCategories from "./components/TableHeaderCategories";
import useTableCtx from "./tableContext/useTableCtx";
import { TableLoading } from "./components/TableLoading";
import ContentCard from "../misc/ContentCard";
import { TablePagination } from "@mui/material";
import TableGlobalSearch from "./components/TableGlobalSearch";
import TableGlobalFilter from "./components/TableGlobalFilter";
import TableProvider from "./tableContext/TableProvider";
import {
  TableActions,
  TableData,
  TableFilterDataItem,
  TableHeaderCategoryConfig,
} from "./tableContext/TableContext";
import TableItemExtra from "./TableItemExtra";

export type TableItemId = string | number;

type Props = {
  className?: string;
  tableHeaderData: TableHeaderCategoryConfig[];
  tableItemsData: TableData[];
  globalFilter?: TableFilterDataItem[];
  withGlobalSearch?: boolean;
  withGlobalFilter?: boolean;
  borderedRow?: boolean;
  ActionButtonsComponent?: any;
  CustomHeaderComponent?: any;
  tableTitle?: string;
  showSearchForCategories?: boolean;
  withTablePagination?: boolean;
  handleTableActions?: (value: TableActions) => void;
  tableLoading?: boolean;
  totalItems?: number;
};

const TableWidget: React.FC<Props> = (props) => {
  return (
    <TableProvider>
      <TableComponent {...props} />
    </TableProvider>
  );
};

const TableComponent: React.FC<Props> = ({
  globalFilter,
  tableHeaderData,
  tableItemsData,
  className,
  withGlobalSearch,
  withGlobalFilter,
  borderedRow,
  ActionButtonsComponent,
  CustomHeaderComponent,
  tableTitle,
  showSearchForCategories,
  withTablePagination = true,
  handleTableActions,
  tableLoading,
  totalItems,
}) => {
  const tableContext = useTableCtx()!;

  const {
    tableData,
    tableHeader,
    tableSearch,
    tablePagination,
    setTablePagination,
    setTableGlobalSearch,
    setTableGlobalFilter,
    setGlobalFilterOptions,
    setTableData,
    setTableHeader,
    globalFilterOptions,
    tableGlobalSearch,
    tableFilter,
    selectedItems,
  } = tableContext;

  useEffect(() => {
    tableItemsData && setTableData(tableItemsData);
    tableHeaderData && setTableHeader(tableHeaderData);
    globalFilter && setGlobalFilterOptions(globalFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, tableHeaderData, tableItemsData]);

  useEffect(() => {
    const getTableActions = () => {
      if (handleTableActions && tablePagination.page && tableFilter) {
        handleTableActions({
          tableSearch,
          tablePagination,
          globalFilterOptions,
          tableGlobalSearch,
          tableFilter,
          selectedItems,
        });
      }
    };
    getTableActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableSearch,
    tablePagination,
    globalFilterOptions,
    tableGlobalSearch,
    tableFilter,
    selectedItems,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setTablePagination((prev) => {
      return {
        ...prev,
        page: newPage + 1,
      };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTablePagination((prev) => {
      return {
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
      };
    });
  };

  return (
    <ContentCard
      className={`card w-100 ${className}`}
      cardBodyStyle={{ padding: 0, margin: 0 }}
      cardHeaderClassName="card-header border-0 py-5 ps-1 text-center"
      cardTitleStyle={{ fontWeight: "bolder" }}
      cardTitle={tableTitle}
      CardHeader={() => <></>}>
      {/* begin::Table container */}
      <div className="table-responsive">
        {/* search start */}
        {(withGlobalSearch || withGlobalFilter) && (
          <div className="d-flex">
            {withGlobalSearch && (
              <TableGlobalSearch setTableGlobalSearch={setTableGlobalSearch} />
            )}
            {withGlobalFilter && globalFilterOptions && (
              <TableGlobalFilter
                filterData={globalFilterOptions}
                setTableGlobalFilter={setTableGlobalFilter}
              />
            )}
          </div>
        )}
        {CustomHeaderComponent && (
          <CustomHeaderComponent
            tableItemsData={tableItemsData}
            tableContext={tableContext}
          />
        )}
        {/* search end */}
        {/* begin::Table */}
        <table
          className={`table align-middle gs-0 gy-4 table-row-gray-100 ${
            borderedRow && "table-row-bordered"
          }`}>
          {/* begin::Table head */}
          <thead>
            <TableHeaderCategories
              showActionButtons={!!ActionButtonsComponent}
              tableHeaderCategories={tableHeader}
              showSearchForCategories={showSearchForCategories}
            />
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {/* @ts-ignore */}
            {tableData?.map(({ id, data, crudData, extraData }) => {
              return (
                <React.Fragment key={id}>
                  <TableItem
                    tableItemId={id}
                    crudData={crudData}
                    tableItemData={data}
                    ActionButtonsComponent={ActionButtonsComponent}
                  />
                  {extraData?.map((extraDataItem, idx) => {
                    return (
                      <TableItemExtra extraData={extraDataItem} key={idx} />
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
      {withTablePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems ?? 1}
          rowsPerPage={tablePagination.rowsPerPage}
          page={tablePagination.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<span className="mb-0">Receptii:</span>}
          labelDisplayedRows={({ page }) => {
            return <span className="mb-0">Pagina: {page + 1}</span>;
          }}
          backIconButtonProps={{
            color: "primary",
          }}
          nextIconButtonProps={{ color: "primary" }}
          SelectProps={{
            inputProps: {
              "aria-label": "page number",
            },
          }}
          showFirstButton={true}
          showLastButton={true}
        />
      )}

      {tableLoading && <TableLoading />}
    </ContentCard>
  );
};

export { TableWidget };
