import React from "react";
import { Modal } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Text from "../styleguide/Text";
import { useAppSelector } from "../redux/hooks";

const CIRCULAR_PROGRESS_SIZE = 30;

const CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE = {
  width: CIRCULAR_PROGRESS_SIZE,
  height: CIRCULAR_PROGRESS_SIZE,
};

function GlobalLoading() {
  const { loadingGlobal } = useAppSelector((state) => ({
    loadingGlobal: state.globalComponents.loadingGlobal,
  }));

  return (
    <Modal
      tabIndex={1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered"
      style={{
        zIndex: 99999,
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
      show={loadingGlobal.open}>
      <div className="modal-body py-lg-10 px-lg-10 d-flex flex-row align-items-center justify-content-between">
        {loadingGlobal.loadingAlign === "left" ? (
          <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
        ) : (
          <div style={CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE} />
        )}
        <div>
          {loadingGlobal.title && (
            <Text className="my-2" variant="h2">
              {loadingGlobal.title}
            </Text>
          )}
          {loadingGlobal.subtitle && (
            <Text className="my-2 text-muted" variant="body1">
              {loadingGlobal.subtitle}
            </Text>
          )}
        </div>
        {loadingGlobal.loadingAlign === "right" ? (
          <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
        ) : (
          <div style={CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE} />
        )}
      </div>
    </Modal>
  );
}

export default GlobalLoading;
