import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getStatistics = createAsyncThunk(
  "/statistics",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/statistics`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/statistics", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
