import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer from "../reducer/auth.reducer";
import appReducer from "../reducer/app.reducer";
import userReducer from "../reducer/user.reducer";
import generalReducer from "../reducer/general.reducer";
import globalComponentsReducer from "../reducer/globalComponents.reducer";
import usersReducer from "../reducer/users.reducer";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appConfig: appReducer,
    user: userReducer,
    general: generalReducer,
    globalComponents: globalComponentsReducer,
    users: usersReducer,
  },
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
