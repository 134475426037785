import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import routesConstants from "../routesConstants";
import { Link } from "react-router-dom";

interface AcordionDataInterface {
  label: string;
  href: string;
  isAcordion?: boolean;
  subcategory?: {
    label: string;
  }[];
}

const acordionData: AcordionDataInterface[] = [
  {
    label: "Statistics",
    href: routesConstants.statistics,
  },
  {
    label: "Users details",
    href: routesConstants.initialMainRoute,
  },
  {
    label: "Posts",
    href: routesConstants.posts,
  },
];

export default function DrawerAcordionItem() {
  return (
    <div>
      {acordionData.map(({ isAcordion, label, subcategory, href }, index) => {
        if (!isAcordion) {
          return (
            <ListItem key={index}>
              <Link to={href} className="text-black w-100">
                <ListItemButton>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </Link>
            </ListItem>
          );
        }

        return (
          <div key={index}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${label}-content`}
                id={`${label}-header`}>
                <Typography>{label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {subcategory?.map(({ label }, index) => {
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </div>
        );
      })}
    </div>
  );
}
