import { createSlice } from "@reduxjs/toolkit";
import {
  getAppUsers,
  getUserPosts,
  postBlockUser,
  postDeletePost,
  postUnpairDevice,
} from "../api/users.api";
import {
  UserPostsInterface,
  UsersDataInterface,
} from "../interfaces/users.interface";

const initialGlobalComponentsState: {
  appUsers: UsersDataInterface;
  userPosts: UserPostsInterface;
} = {
  appUsers: {
    data: {
      nrPagini: 1,
      nrTotalUtilizatori: 1,
      utilizatori: [],
    },
    isLoadingUsers: false,
    isErrorUsers: false,
    errorMessageUsers: "",
  },
  userPosts: {
    data: {
      nrPagini: 1,
      nrTotalPostari: 1,
      postari: [],
    },
    isLoadingPosts: false,
    isErrorPosts: false,
    errorMessagePosts: "",
  },
};

const usersReducer = createSlice({
  name: "users",
  initialState: initialGlobalComponentsState,
  reducers: {
    clearPosts(state) {
      return {
        ...state,
        userPosts: {
          ...initialGlobalComponentsState.userPosts,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // get users start
    builder.addCase(getAppUsers.pending, (state) => {
      return {
        ...state,
        appUsers: {
          ...initialGlobalComponentsState.appUsers,
          isLoadingUsers: true,
        },
      };
    });
    builder.addCase(getAppUsers.rejected, (state, action: any) => {
      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          isLoadingUsers: false,
          errorMessageUsers: action.payload.message,
          isErrorUsers: true,
        },
      };
    });
    builder.addCase(getAppUsers.fulfilled, (state, action) => {
      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          isLoadingUsers: false,
          data: action.payload,
        },
      };
    });
    // get users end
    // unpair start
    builder.addCase(postUnpairDevice.fulfilled, (state, action: any) => {
      const removedDeviceId = action?.payload;

      const removeMacId = state.appUsers.data.utilizatori.map((user) => {
        const userDeviceId = user?.id_user_device
          ? Number(user.id_user_device)
          : null;

        if (userDeviceId == removedDeviceId) {
          return {
            ...user,
            mac_id: null,
            id_user_device: null,
          };
        }
        return user;
      });

      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          data: {
            ...state.appUsers.data,
            utilizatori: removeMacId,
          },
        },
      };
    });
    // unpair end
    // block start
    builder.addCase(postBlockUser.fulfilled, (state, action: any) => {
      const blockedUserId = action?.payload;

      const removeMacId = state.appUsers.data.utilizatori.map((user) => {
        const userId = user?.id_user ? Number(user.id_user) : null;

        if (userId == blockedUserId) {
          return {
            ...user,
            blocked: user.blocked === 1 ? 0 : 1,
          };
        }
        return user;
      });

      return {
        ...state,
        appUsers: {
          ...state.appUsers,
          data: {
            ...state.appUsers.data,
            utilizatori: removeMacId,
          },
        },
      };
    });
    // block end
    // user posts start
    builder.addCase(getUserPosts.pending, (state) => {
      return {
        ...state,
        userPosts: {
          ...initialGlobalComponentsState.userPosts,
          isLoadingPosts: true,
        },
      };
    });
    builder.addCase(getUserPosts.rejected, (state, action: any) => {
      return {
        ...state,
        userPosts: {
          ...state.userPosts,
          isLoadingPosts: false,
          errorMessagePosts: action.payload.message,
          isErrorPosts: true,
        },
      };
    });
    builder.addCase(getUserPosts.fulfilled, (state, action) => {
      return {
        ...state,
        userPosts: {
          ...state.userPosts,
          isLoadingPosts: false,
          data: action.payload,
        },
      };
    });
    // user posts end
    // delete user post start
    builder.addCase(postDeletePost.fulfilled, (state, action) => {
      const removePost = state.userPosts.data.postari.filter(
        (post) => post.id !== action.payload,
      );

      return {
        ...state,
        userPosts: {
          ...state.userPosts,
          data: {
            ...state.userPosts.data,
            postari: removePost,
          },
        },
      };
    });
    // delete user post end
  },
});

export default usersReducer.reducer;
