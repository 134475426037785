import AppContainerWrapper from "../components/AppContainerWrapper";
import GlobalLoading from "../components/GlobalLoading";
import useMeasure from "../hooks/useMeasure";
import { useAppSelector } from "../redux/hooks";
import { Footer } from "./Footer";
import GlobalToast from "./GlobalToast";
import NavbarPersistWrapper from "./Navbar/NavbarPersistWrapper";

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const {
    auth: { token },
  } = useAppSelector((state) => ({
    auth: state.auth,
  }));

  return (
    <div className="w-100 vh-100">
      <GlobalLoading />
      <MainComponent token={token} children={children} />
      <GlobalToast />
    </div>
  );
};

const MainComponent = ({ token, children }: any) => {
  const { ref, size, windowSize } = useMeasure();

  const height = windowSize.height - size.height - 64;

  if (!token) {
    return <>{children}</>;
  }
  return (
    <NavbarPersistWrapper>
      <AppContainerWrapper style={{ minHeight: height }}>
        {children}
      </AppContainerWrapper>
      <div ref={ref}>
        <Footer />
      </div>
    </NavbarPersistWrapper>
  );
};

export default Layout;
