import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getAppUsers = createAsyncThunk(
  "/users/displayUsers",
  async (
    filters: {
      page?: number;
      perPage?: number;
      search?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, search } = filters;

    try {
      const { data } = await apiRequest(
        `displayUsers?per_page=${perPage}&page=${page}&search=${search}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/users/displayUsers", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUnpairDevice = createAsyncThunk(
  "/users/postUnpairDevice",
  async (
    {
      idUserDevice,
    }: {
      idUserDevice: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`unpairDevice/${idUserDevice}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/users/postUnpairDevice", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postBlockUser = createAsyncThunk(
  "/users/postBlockUser",
  async (
    {
      userId,
      blocked,
    }: {
      userId: number;
      blocked: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        `blockUser/${userId}?blocked=${blocked}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/users/postBlockUser", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getUserPosts = createAsyncThunk(
  "/getUserPosts",
  async (
    params: {
      idUser: number;
      page: number;
      perPage: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const { idUser, page, perPage } = params;
      const { data } = await apiRequest(
        `/posts?filter=3&idUser=${idUser}&page=${page}&items_per_page=${perPage}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/getUserPosts", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postDeletePost = createAsyncThunk(
  "/users/deletePost",
  async (
    {
      idPost,
    }: {
      idPost: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await apiRequest(`deletePost/${idPost}`, "GET");
      return idPost;
    } catch (err: any) {
      console.warn("/users/deletePost", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
