import { Box } from "@mui/material";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { getAppUsers } from "../../redux/api/users.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import UsersTableActionButtons from "./tableActionButtons/UsersTableActionButtons";
import {
  getUsersTableData,
  usersTableHeader,
} from "./tableConfigs/usersTableConfig";

function Home() {
  const dispatch = useAppDispatch();

  const {
    users: { appUsers },
  } = useAppSelector((state) => ({
    users: state.users,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getAppUsers({
          search: action.tableGlobalSearch,
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <Box className="d-flex flex-wrap">
      <TableWidget
        tableTitle="Users"
        tableHeaderData={usersTableHeader}
        tableItemsData={getUsersTableData(appUsers.data.utilizatori)}
        tableLoading={appUsers.isLoadingUsers}
        handleTableActions={handleTableActions}
        totalItems={appUsers.data.nrTotalUtilizatori}
        withGlobalSearch
        showSearchForCategories={false}
        ActionButtonsComponent={(props: any) => (
          <UsersTableActionButtons {...props} />
        )}
      />
    </Box>
  );
}

export default Home;
