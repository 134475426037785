import * as Yup from "yup";
import { Formik } from "formik";

import { LoadingButton } from "@mui/lab";
import Text from "../../styleguide/Text";
import { fetchUserToken } from "../../redux/api/auth.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TextInput from "../../components/misc/TextInput";
import ContentCard from "../../components/misc/ContentCard";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minim 3 caractere")
    .max(50, "Maxim 50 caractere")
    .required("Adaugati un nume de utilizator"),
  password: Yup.string()
    .min(3, "Minim 3 caractere")
    .max(50, "Maxim 50 caractere")
    .required("Adaugati o parola"),
});

const initialValues = {
  name: "",
  password: "",
};

const Login = () => {
  const dispatch = useAppDispatch();

  const {
    auth: { isLoadingToken },
    user: { isLoadingUser },
  } = useAppSelector((state) => ({
    auth: state.auth,
    user: state.user,
  }));

  const isLoading = isLoadingToken || isLoadingUser;

  const handleLogin = async (values: any) => {
    const userData = {
      userName: values.name,
      password: values.password,
    };
    dispatch(fetchUserToken(userData));
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 ">
      <ContentCard
        cardTitle="Login"
        className="mw-500px w-100"
        cardHeaderClassName="text-center"
        CardHeader={() => <></>}>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={handleLogin}>
          {({
            status,
            isValid,
            errors,
            touched,
            getFieldProps,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isSubmitting,
            values,
          }) => (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              {status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              ) : (
                <div className="mb-10 bg-light-info p-8 rounded">
                  <Text variant="body1" className="text-info">
                    Add your account
                  </Text>
                </div>
              )}
              <TextInput
                name="name"
                label="Username"
                error={errors["name"]}
                touched={touched["name"]}
                inputProps={getFieldProps("name")}
              />
              <TextInput
                name="password"
                label="Password"
                error={errors["password"]}
                touched={touched["password"]}
                inputProps={getFieldProps("password")}
              />
              <div className="text-center">
                <LoadingButton
                  type="submit"
                  fullWidth
                  className="btn btn-lg btn-primary mb-5"
                  disabled={!isValid || isSubmitting}
                  loading={isLoading}>
                  <Text variant="body1" className="indicator-label" isBold>
                    Continua
                  </Text>
                </LoadingButton>
              </div>
            </form>
          )}
        </Formik>
      </ContentCard>
    </div>
  );
};

export default Login;
