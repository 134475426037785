import { CircularProgress } from "@mui/material";
import React from "react";
import Text from "../../styleguide/Text";

type CardProps = {
  children: React.ReactNode | JSX.Element | JSX.Element[];
  cardTitle?: string;
  cardSubtitle?: string;
  CardHeader?: () => React.ReactNode | JSX.Element | JSX.Element[];
  CardFooter?: () => React.ReactNode | JSX.Element | JSX.Element[];
  className?: string;
  cardBodyClassName?: string;
  cardHeaderClassName?: string;
  cardFooterClassName?: string;
  cardTitleClassName?: string;
  cardSubtitleClassName?: string;
  cardContainerStyle?: React.CSSProperties;
  cardBodyStyle?: React.CSSProperties;
  cardHeaderStyle?: React.CSSProperties;
  cardFooterStyle?: React.CSSProperties;
  cardTitleStyle?: React.CSSProperties;
  cardSubtitleStyle?: React.CSSProperties;
  fullWidth?: boolean;
  isLoading?: boolean;
  isLoadingClassName?: string;
};

function ContentCard({
  children,
  cardTitle,
  cardSubtitle,
  CardHeader,
  CardFooter,
  className = "",
  cardHeaderClassName = "",
  cardTitleClassName = "",
  cardSubtitleClassName = "",
  cardBodyClassName = "",
  cardFooterClassName = "",
  cardContainerStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardSubtitleStyle,
  cardBodyStyle,
  cardFooterStyle,
  fullWidth = false,
  isLoading,
  isLoadingClassName,
}: CardProps) {
  return (
    <div
      className={`shadow-xs card-rounded mx-3 mb-9 px-6 py-9 bg-white ${className} ${
        fullWidth && "w-100"
      }`}
      style={cardContainerStyle}>
      {CardHeader && (
        <div
          className={`card-header align-items-center border-0 pt-5 ${cardHeaderClassName}`}
          style={cardHeaderStyle}>
          {cardTitle && (
            <Text
              variant="h3"
              component="h3"
              className={`card-title mb-4 ${cardTitleClassName}`}
              style={cardTitleStyle}
              gutterBottom>
              {cardTitle}
            </Text>
          )}
          {cardSubtitle && (
            <Text
              variant="h4"
              component="h4"
              className={`card-title ${cardSubtitleClassName}`}
              style={cardSubtitleStyle}
              gutterBottom>
              {cardSubtitle}
            </Text>
          )}
          {CardHeader()}
        </div>
      )}
      {isLoading ? (
        <div className={`${isLoadingClassName}`}>
          <CircularProgress />
        </div>
      ) : (
        <div
          className={`card-body card-scroll ${cardBodyClassName}`}
          style={cardBodyStyle}>
          {children}
        </div>
      )}
      {CardFooter && (
        <div
          className={`card-footer ${cardFooterClassName}`}
          style={cardFooterStyle}>
          {CardFooter()}
        </div>
      )}
    </div>
  );
}

export default ContentCard;
