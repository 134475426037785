import { Box } from "@mui/material";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { getUserPosts } from "../../redux/api/users.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import UsersTableActionButtons from "./tableActionButtons/PostsTableActionButtons";
import {
  getPostsTableData,
  postsTableHeader,
} from "./tableConfigs/postsTableConfig";
import { useEffect } from "react";

function Posts() {
  const dispatch = useAppDispatch();

  const {
    users: { userPosts },
    user: { userId },
  } = useAppSelector((state) => ({
    users: state.users,
    user: state.user,
  }));

  useEffect(() => {
    if (userId) {
      dispatch(
        getUserPosts({
          idUser: userId,
          page: 1,
          perPage: 25,
        }),
      );
    }
  }, [dispatch, userId]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      userId
    ) {
      dispatch(
        getUserPosts({
          idUser: userId,
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <Box className="d-flex flex-wrap">
      <TableWidget
        tableTitle="Posts"
        tableHeaderData={postsTableHeader}
        tableItemsData={getPostsTableData(userPosts.data.postari)}
        tableLoading={userPosts.isLoadingPosts}
        handleTableActions={handleTableActions}
        totalItems={userPosts.data.nrTotalPostari}
        showSearchForCategories={false}
        ActionButtonsComponent={(props: any) => (
          <UsersTableActionButtons {...props} />
        )}
      />
    </Box>
  );
}

export default Posts;
