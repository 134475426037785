import { memo } from "react";
import CustomButton from "../../../components/CustomButton";
import { TableItemType } from "../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../components/tables/TableWidget";
import { postDeletePost } from "../../../redux/api/users.api";
import { useAppDispatch } from "../../../redux/hooks";
import Text from "../../../styleguide/Text";
import { UserPostsListInterface } from "../../../redux/interfaces/users.interface";

function PostsTableActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData: UserPostsListInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(
      postDeletePost({
        idPost: crudData.id,
      }),
    );
  };

  return (
    <td className="text-start" style={{ width: 250 }}>
      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        withConfirmationModal={{
          modalRightButtonOnClick: handleDelete,
          modalTitle: `Delete user post ${crudData.userName}`,
          modalSubtitle: "Are you sure you want to delete this post?",
        }}>
        <Text variant="caption" className="indicator-label" isBold>
          Delete
        </Text>
      </CustomButton>
    </td>
  );
}

export default memo(PostsTableActionButtons);
