import DrawerAcordionItem from "./DrawerAcordionItem";

const DrawerItems = () => {
  return (
    <div>
      <DrawerAcordionItem />
    </div>
  );
};

export default DrawerItems;
