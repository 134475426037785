import { createSlice } from "@reduxjs/toolkit";
import { getStatistics } from "../api/general.api";
import { StatisticsInterface } from "../interfaces/general.interface";

export const defaultStateGeneral: { statistics: StatisticsInterface } = {
  statistics: {
    data: null,
    isStatisticsError: false,
    isStatisticsLoading: false,
    statisticsErrorMessage: "",
  },
};

const general = createSlice({
  name: "general",
  initialState: defaultStateGeneral,
  reducers: {
    clearStatistics(state) {
      return {
        ...state,
        statistics: defaultStateGeneral.statistics,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(getStatistics.pending, () => {
      return {
        ...defaultStateGeneral,
        statistics: {
          ...defaultStateGeneral.statistics,
          isStatisticsLoading: true,
        },
      };
    });
    builder.addCase(getStatistics.rejected, (state, action: any) => {
      return {
        ...state,
        statistics: {
          ...defaultStateGeneral.statistics,
          statisticsErrorMessage: action.error.message,
          isStatisticsError: true,
        },
      };
    });
    builder.addCase(getStatistics.fulfilled, (state, action) => {
      return {
        ...state,
        statistics: {
          ...defaultStateGeneral.statistics,
          data: action.payload,
        },
      };
    });
  },
});

export const { clearStatistics } = general.actions;

export default general.reducer;
