import { useEffect } from "react";
import BarChart from "../../components/misc/partials/widgets/charts/BarChart";
import PieChart from "../../components/misc/partials/widgets/charts/PieChart";
import { getStatistics } from "../../redux/api/general.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const OTHER_COUNTRIES_SEPARATOR = 80;

function Statistics() {
  const dispatch = useAppDispatch();

  const {
    general: { statistics },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  const getMainCountries =
    statistics.data?.countries.filter(
      ({ count, adress }) => count >= OTHER_COUNTRIES_SEPARATOR && adress,
    ) ?? [];
  const getOtherCountries =
    statistics.data?.countries.filter(
      ({ count, adress }) => count < OTHER_COUNTRIES_SEPARATOR || adress === "",
    ) ?? [];

  const concatOtherCountries = getOtherCountries.reduce((acc, obj) => {
    return acc + obj.count;
  }, 0);

  const mainCountriesObject = {
    count: getMainCountries.map(({ count }) => count),
    adress: getMainCountries.map(({ adress }) => adress),
  };

  const otherCountriesObject = {
    count: concatOtherCountries,
    adress: "Other countries",
  };

  const countryWithMostUsers: any = getMainCountries.reduce(
    (acc, obj) => {
      return acc.count > obj.count ? acc : obj;
    },
    { count: 0 },
  );

  const countriesData = {
    count: [...mainCountriesObject.count, otherCountriesObject.count],
    adress: [...mainCountriesObject.adress, otherCountriesObject.adress],
  };

  const scooterTypes =
    statistics.data?.scooterTypes.filter(({ deviceName }) => deviceName) ?? [];

  const scooterTypesCount = scooterTypes.map(({ count }) => count);
  const scooterTypesDeviceName = scooterTypes.map(
    ({ deviceName }) => deviceName,
  );
  const scooterTypesKm = scooterTypes.map(({ km }) => km);

  const scooterTypesData = {
    count: scooterTypesCount,
    deviceName: scooterTypesDeviceName,
    km: scooterTypesKm,
  };

  if (!statistics.data) {
    return <></>;
  }

  return (
    <div
    // className="d-flex flex-wrap gap-2 w-100"
    >
      <div className="row g-5 g-xl-8 mb-5">
        <PieChart
          title={`Users by country (${statistics.data?.totalUsers} in total)`}
          subtitle={`Country with most users: ${countryWithMostUsers?.adress} (${countryWithMostUsers.count})`}
          data={countriesData.count}
          categories={countriesData.adress}
          height={500}
          labelHoverFormatter={(value) => `${value} users`}
        />
      </div>

      <div className="row g-5 g-xl-8 mb-5">
        <div className="col-xl-6">
          <BarChart
            title="Scooters paired"
            subtitle={`Total scooters paired ${statistics.data.totalPairedScooters}`}
            data={[
              {
                name: "Total scooters paired",
                data: scooterTypesData.count,
              },
            ]}
            categories={scooterTypesData.deviceName}
            height={500}
            labelHoverFormatter={(value) => `${value} users`}
          />
        </div>
        <div className="col-xl-6">
          <BarChart
            title="Scooters distance travelled"
            subtitle={`Total scooters distance travelled ${statistics.data.scooterTotalKm}`}
            data={[
              {
                name: "Total km",
                data: scooterTypesData.km,
              },
            ]}
            categories={scooterTypesData.deviceName}
            height={500}
            labelHoverFormatter={(value) => `${value} km`}
          />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
