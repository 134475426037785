import { SnackbarOrigin } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

export interface ToastGlobalState extends SnackbarOrigin {
  open: boolean;
  message: string;
  severity?: "success" | "error" | "info" | "warning";
  autoHideDuration: number;
}

interface GlobalComponentsState {
  toastGlobal: ToastGlobalState;
  loadingGlobal: {
    open: boolean;
    title?: string;
    subtitle?: string;
    loadingAlign?: "left" | "right";
  };
}

const initialGlobalComponentsState: GlobalComponentsState = {
  toastGlobal: {
    open: false,
    vertical: "bottom",
    horizontal: "right",
    message: "",
    severity: "info",
    autoHideDuration: 4000,
  },
  loadingGlobal: {
    open: false,
    title: "",
    subtitle: "",
    loadingAlign: "right",
  },
};

const globalComponents = createSlice({
  name: "globalComponents",
  initialState: initialGlobalComponentsState,
  reducers: {
    setGlobalToast: (state, action: any) => {
      return {
        ...state,
        toastGlobal: {
          ...state.toastGlobal,
          open: true,
          ...action.payload,
        },
      };
    },
    closeGlobalToast: (state) => {
      return {
        ...state,
        toastGlobal: {
          ...initialGlobalComponentsState.toastGlobal,
          open: false,
        },
      };
    },
    setGlobalLoading: (state, action: any) => {
      return {
        ...state,
        loadingGlobal: {
          ...state.loadingGlobal,
          open: true,
          ...action.payload,
        },
      };
    },
    closeGlobalLoading: (state) => {
      return {
        ...state,
        loadingGlobal: {
          ...initialGlobalComponentsState.loadingGlobal,
          open: false,
        },
      };
    },
  },
});

export const {
  setGlobalToast,
  closeGlobalToast,
  setGlobalLoading,
  closeGlobalLoading,
} = globalComponents.actions;

export default globalComponents.reducer;
