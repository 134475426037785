import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import { appWasLoaded } from "../reducer/app.reducer";

export const fetchUser = createAsyncThunk(
  "/user",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/user", "POST");
      dispatch(appWasLoaded());
      return data;
    } catch (err: any) {
      dispatch(appWasLoaded());
      console.warn("/products", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
