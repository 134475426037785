import React, { useEffect, useState } from "react";

function TableGlobalFilter({
  filterData,
  setTableGlobalFilter,
}: {
  filterData: { label: string; value: number }[];
  setTableGlobalFilter?: (value: string) => void;
}) {
  const [filterTerm, setFilterTerm] = useState<string>("");

  useEffect(() => {
    setTableGlobalFilter && setTableGlobalFilter(filterTerm);
  }, [filterTerm]);

  return (
    <div className="card-title fw-bolder text-muted bg-light w-100 p-3 rounded">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        <select
          name={"select-filter"}
          data-control={"select-filter"}
          data-hide-search="true"
          className={`form-select form-select-sm form-select-solid bg-white min-w-130px`}
          defaultValue={0}
          onChange={(e) => setFilterTerm(e.target.value)}>
          {filterData.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {/* end::Search */}
    </div>
  );
}

export default TableGlobalFilter;
